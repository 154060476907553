import React, { useState } from 'react';
import './index.css';
import EdonaRexhepi from '../../images/team/edonarexhepi.jpeg';

const Profile = () => {
  const [page, setPage] = useState(1);

  const { name, title, image, about, profile, contact, interests, experiences: experiencesList, education: educationList, other: otherList } = {
    name: 'Edona Rexhepi',
    image: EdonaRexhepi,
    title: 'Chief Marketing Officer',
    about: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`,
    profile: [
      {
        text: 'Edona Rexhepi',
        icon: <span class="material-icons">person</span>,
      },
      {
        text: '23 Aug, 1997',
        icon: <span class="material-icons">calendar_month</span>,
      },
      {
        text: 'Austria',
        icon: <span class="material-icons">map</span>,
      },
      {
        text: 'Albanian, German, English, Turkish',
        icon: <span class="material-icons">language</span>,
      },
    ],
    contact: [
      {
        text: '(+43) 67764006052',
        icon: <span class="material-icons">phone</span>,
      },
      {
        text: 'edona@coetic-x.com',
        icon: <span class="material-icons">alternate_email</span>,
      },
      {
        text: 'Sedlitzkygasse 17-19, 14 1110 Wien, Austria',
        icon: <span class="material-icons">location_on</span>,
      },
    ],
    interests: [
      {
        text: 'Hiking',
        icon: <span class="material-icons">hiking</span>,
      },
      {
        text: 'Reading',
        icon: <span class="material-icons">menu_book</span>,
      },
    ],
    experiences: [
      {
        time: 'Sep 2021 - <span style="font-weight:500">Present</span>',
        title: 'Coetic X',
        previousTitle: '',
        position: 'Chief Marketing Officer',
        content: `Experienced Chief Marketing Officer adept at achieving remarkable outcomes. Strategic visionary and influential leader, focused on enhancing marketing efforts, expanding brand reach, and attracting new customers. Skilled in developing successful marketing strategies, fostering high-performing teams, and utilizing data-driven insights to optimize campaigns and drive maximum returns on investment`,
        projects: [
          {
            title: 'Coetic x',
            description:
              "Responsibly maintained and consistently produced captivating content for company's Instagram and Facebook, resulting in increased engagement and heightened brand visibility",
            links: ['https://www.instagram.com/coeticx/?hl=am-et', 'https://www.facebook.com/coeticx'],
          },
        ],
      },
      {
        time: 'Jun 2013 - Nov 2021',
        title: 'Eurogoma Shpk',
        previousTitle: '',
        position: 'Project Manager',
        projects: [
          {
            title: 'Lorem ipsum',
            description:
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
            links: ['www.eurogoma.net'],
          },
        ],
      },
      {
        time: 'May 2016 - Jun 2019',
        title: 'Auto Moto Club Kosova',
        previousTitle: '',
        position: 'Secretary general',
        projects: [
          {
            title: 'Lorem ipsum',
            description:
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
            links: ['www.eurogoma.net'],
          },
        ],
      },
      {
        time: 'Jun 2013 - Nov 2021',
        title: 'Auto Sports Federation of Kosovo - FASK',
        previousTitle: '',
        position: 'Technical Secretary',
        projects: [
          {
            title: 'Lorem ipsum',
            description:
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
            links: ['https://fask.coetic-x.com/'],
          },
        ],
      },
    ],
    education: [
      {
        time: 'Oct 2021 - Current',
        title: 'Master of History',
        previousTitle: '',
        position: `University of Vienna`,
        content: `Field of study Contemporary History, Social and Economic History`,
      },
      {
        time: 'Oct 2015 - Nov 2018',
        title: 'Bachelor of History',
        previousTitle: '',
        position: `University of Prishtina, Faculty of Philosophie`,
        content: `Field of study History`,
      },
      {
        time: '11 May 2017 - 15 May 2017',
        title: 'Certificate: Officials Training - MSA UK',
        previousTitle: '',
        position: `MSA - UNITED KINGDOM`,
        content: ``,
      },
      {
        time: 'Sep 2013 - Jun 2015',
        title: 'High School Diploma',
        previousTitle: '',
        position: `American School of Kosovo`,
        content: ``,
      },
    ],
    other: [
      {
        time: '',
        title: 'Digital Skills',
        previousTitle: '',
        position: ``,
        content: [
          'Microsoft Office',
          'Microsoft Word',
          'Microsoft Excel',
          'Microsoft Powerpoint',
          'Google Docs',
          'Google Drive',
          'Instagram',
          'Facebook',
          'Outlook',
          'Linkedin',
          'Zoom',
          'Organizational and planning skills',
          'Good listener and communicator',
        ],
      },
      {
        time: '',
        title: 'Organisational skills',
        previousTitle: '',
        position: ``,
        content: ['leadership', 'Good organizational skills', 'Good team-leading skills', 'Office solutions', 'Office management', 'Resource management'],
      },
      {
        time: '',
        title: 'Communication and interpersonal skills',
        previousTitle: '',
        position: ``,
        content: [
          'Absorbing, sharing, and understanding information presented',
          'Using relevant knowledge, know-how, and skills to explain and clarify thoughts and ideas',
        ],
      },
    ],
  };

  const experiences = page === 1 ? experiencesList.slice(0, 4) : experiencesList.slice(4);
  const education = page === 2 ? educationList.slice(0, 4) : educationList.slice(4);
  const other = page === 2 ? otherList.slice(0, 4) : otherList.slice(4);

  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=0.8" />
      <div className="CV">
        <div className="CV--header">
          <div className="CV--header-left-absolute"></div>
          <div className="CV--header-left">
            <img src={image} alt="profile" />
          </div>
          <div className="CV--header-right-absolute"></div>
          <div className="CV--header-right">
            <h1 className="CV--header-name">{name}</h1>
            <p className="CV--header-title" dangerouslySetInnerHTML={{ __html: title }} />
          </div>
        </div>
        <div className="CV--body">
          <div className="CV--left-sidebar-absolute"></div>
          <div className="CV--left-sidebar">
            {page === 1 && (
              <>
                <div className="CV--left-box CV--left-profile">
                  <h4 className="CV--left-box-header">Profile</h4>
                  {profile.map(({ text, icon }) => (
                    <div className="CV--left-box--content">
                      {icon}
                      {text}
                    </div>
                  ))}
                </div>
                <div className="CV--left-box CV--left-contact">
                  <h4 className="CV--left-box-header">Contact</h4>
                  {contact.map(({ text, icon }) => (
                    <div className="CV--left-box--content">
                      {icon}
                      {text}
                    </div>
                  ))}
                </div>
                <div className="CV--left-box CV--left-interests">
                  <h4 className="CV--left-box-header">Hobbies & Interests</h4>
                  {interests.map(({ text, icon }) => (
                    <div className="CV--left-box--content">
                      {icon}
                      {text}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="CV--right-sidebar-absolute"></div>
          <div className="CV--right-sidebar">
            {page === 1 && (
              <div className="CV--right-header">
                <h3 className="CV--right-box-header">About Me</h3>
                <p>{about}</p>
              </div>
            )}
            <div className="CV--right-content">
              {Boolean(page === 1) && (
                <>
                  <h3 className="CV--right-box-content">Experience</h3>
                  {experiences.map(({ time, title, previousTitle, position, projects, content }) => (
                    <div className="CV--right-box-content-exp">
                      <p dangerouslySetInnerHTML={{ __html: time }} />
                      <div>
                        <h6>
                          {title} <span>{previousTitle}</span>
                        </h6>
                        <p className="CV--right-box-content-exp--position">{position}</p>
                        {Boolean(content) && <p className="CV--right-box-content-exp--content">{content}</p>}
                        {Boolean(projects?.length) &&
                          projects.map(({ title, description, links }) => (
                            <p className="CV--right-box-content-exp--project">
                              <span>{title}</span>
                              <br />
                              {description}
                              {Boolean(links?.length) &&
                                links?.map(link => (
                                  <>
                                    <br />
                                    <a href={link} target="_blank" rel="noreferrer">
                                      {link}
                                    </a>
                                  </>
                                ))}
                            </p>
                          ))}
                      </div>
                    </div>
                  ))}
                </>
              )}

              {Boolean(page === 2) && (
                <>
                  <h3 className="CV--right-box-content">Education</h3>
                  {education.map(({ time, title, previousTitle, position, projects, content }) => (
                    <div className="CV--right-box-content-exp">
                      <p dangerouslySetInnerHTML={{ __html: time }} />
                      <div>
                        <h6>
                          {title} <span>{previousTitle}</span>
                        </h6>
                        <p className="CV--right-box-content-exp--position">{position}</p>
                        {Boolean(content) && <p className="CV--right-box-content-exp--content">{content}</p>}
                        {Boolean(projects?.length) &&
                          projects.map(({ title, description, links }) => (
                            <p className="CV--right-box-content-exp--project">
                              <span>{title}</span>
                              <br />
                              {description}
                              {Boolean(links?.length) &&
                                links?.map(link => (
                                  <>
                                    <br />
                                    <a href={link} target="_blank" rel="noreferrer">
                                      {link}
                                    </a>
                                  </>
                                ))}
                            </p>
                          ))}
                      </div>
                    </div>
                  ))}
                </>
              )}

              {Boolean(page === 2) && (
                <>
                  <h3 className="CV--right-box-content">Skills</h3>
                  {other.map(({ time, title, previousTitle, position, projects, content }) => (
                    <div className="CV--right-box-content-exp">
                      <p dangerouslySetInnerHTML={{ __html: time }} />
                      <div>
                        <h6>
                          {title} <span>{previousTitle}</span>
                        </h6>
                        <p className="CV--right-box-content-exp--position">{position}</p>

                        {Boolean(content?.length) &&
                          content?.map(item => (
                            <>
                              <p className="CV--right-box-content-exp--content" style={{ marginBottom: '0%' }}>
                                • {item}{' '}
                              </p>
                            </>
                          ))}

                        {Boolean(projects?.length) &&
                          projects.map(({ title, description, links }) => (
                            <p className="CV--right-box-content-exp--project">
                              <span>{title}</span>
                              <br />
                              {description}
                              {Boolean(links?.length) &&
                                links?.map(link => (
                                  <>
                                    <br />
                                    <a href={link} target="_blank" rel="noreferrer">
                                      {link}
                                    </a>
                                  </>
                                ))}
                            </p>
                          ))}
                      </div>
                    </div>
                  ))}
                </>
              )}

              <div className="CV--right-box-buttons">
                {page !== 1 && (
                  <button className="no-print left" onClick={() => setPage(a => a - 1)}>
                    <span class="material-icons">keyboard_double_arrow_left</span> Previous
                  </button>
                )}
                {page !== 2 && (
                  <button className="no-print right" onClick={() => setPage(a => a + 1)}>
                    Next <span class="material-icons">keyboard_double_arrow_right</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
